import type { CodeEditorSelection } from '../../CodeEditor';
import type { CustomBlockMenuItem } from '../../CustomBlocksMenu/types';
import type { ExportDefaultDeclaration } from 'estree';
import type { RootContent } from 'mdast';
import type { MdxjsEsm } from 'mdast-util-mdx';
import type { Location } from 'slate';

import * as rmdx from '@readme/mdx';
import { Editor, Node, Range, Text, Transforms } from 'slate';

import { isEmptyNode } from '@ui/MarkdownEditor/emptyNode';
import type { JsxFlowElement } from '@ui/MarkdownEditor/types';

import { offsetToCodeEditorSelection, offsetToPoint, pointToOffset } from '../../utils';

import { isJsxFlowElement, type } from './shared';

interface Opts {
  at?: Location | null;
  codeEditorSelection?: CodeEditorSelection;
}

export const insertJsxFlow = (
  editor: Editor,
  value: string,
  { at = editor.selection, codeEditorSelection }: Opts = {},
) => {
  if (!at) throw new Error('Cannot insert JsxFlowElement without a location');

  if (editor.selection && !codeEditorSelection) {
    const range = Editor.range(editor, at);
    const textEntry = Editor.node(editor, range.anchor.path);

    if (!Text.isText(textEntry[0])) {
      throw new Error('Cannot insert JsxFlowElement into non-text node');
    }

    // eslint-disable-next-line no-param-reassign
    codeEditorSelection = [
      offsetToCodeEditorSelection(pointToOffset(textEntry, editor.selection.anchor), textEntry[0].text),
      offsetToCodeEditorSelection(pointToOffset(textEntry, editor.selection.focus), textEntry[0].text),
    ];
  }

  const jsxFlow: JsxFlowElement = { type, value, children: [{ text: '' }], selection: codeEditorSelection };

  Editor.withoutNormalizing(editor, () => {
    if (Range.isRange(at)) {
      Transforms.delete(editor, { at });
    }

    let parent;
    try {
      parent = Editor.above(editor, { at, match: n => Editor.isBlock(editor, n) && !isJsxFlowElement(n) });
    } catch (e) {
      // noop - parent may not exist
    }

    if (parent && isEmptyNode(parent[0])) {
      /* @note: Deleting/splitting the parent node always leaves a text
       * leaves at the front. To the user, this would plop the new Jsx
       * Element on the next line 🤮. So, we need to remove the previous
       * parent node if it's empty.
       *
       * It might not be empty if the Jsx tag started on a newline, instead
       * of the beginning of a paragraph.
       */
      Transforms.removeNodes(editor, { at: parent[1] });
      Transforms.insertNodes(editor, jsxFlow, { at: parent[1], select: true });
    } else {
      Transforms.insertNodes(editor, jsxFlow, { at, select: true });

      if (parent) {
        const string = Node.string(parent[0]);
        /* @note: If the parent node ends with a newline, then we need to
         * remove it. Otherwise, the Jsx Element will appear to got plopped
         * into the next line.
         */
        if (string.match(/\n$/)) {
          Transforms.delete(editor, { at: offsetToPoint(parent, string.length - 1) });
        }
      }
    }
  });
};

const isMdxjsEsm = (node: RootContent): node is MdxjsEsm => 'type' in node && node.type === 'mdxjsEsm';

const isDefaultExport = (node: RootContent): ExportDefaultDeclaration | false | undefined => {
  if (!isMdxjsEsm(node)) return false;

  return (
    'data' in node &&
    node.data &&
    'estree' in node.data &&
    node.data.estree?.body.find(esNode => esNode.type === 'ExportDefaultDeclaration')
  );
};

const getPlaceholder = ({ name, source }: { name: string; source: string }) => {
  const ast = rmdx.mdast(source);

  const defaultExport: RootContent | undefined = ast.children.find(isDefaultExport);
  if (defaultExport) {
    return `<${name} />`;
  }

  const lastNode: MdxjsEsm | undefined = ast.children
    .reverse()
    .find(node => 'type' in node && node.type === 'mdxjsEsm');

  // @ts-expect-error - position is optional, but should definitely be there
  return (lastNode ? source.slice(lastNode.position.end.offset) : source).trim();
};

export const insertJsxFlowPlaceholder = (
  editor: Editor,
  block: CustomBlockMenuItem,
  { at }: { at: Location | null | undefined },
) => {
  const placeholder = getPlaceholder(block);

  const codeEditorSelection: CodeEditorSelection = [
    offsetToCodeEditorSelection(placeholder.length, placeholder),
    offsetToCodeEditorSelection(placeholder.length, placeholder),
  ];

  insertJsxFlow(editor, placeholder, { at, codeEditorSelection });
};

export default { insertJsxFlow, insertJsxFlowPlaceholder };
