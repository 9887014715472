import type { $TSFixMe } from '@readme/iso';
import type { BaseSelection } from 'slate';

import isEqual from 'lodash/isEqual';
import { Editor, Node, Path, Range, Transforms } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';
import { TableAlignment } from '@ui/MarkdownEditor/enums';
import type {
  ReadmeEditor,
  ReadmeElement,
  TableElement,
  TableRowElement,
  TableCellElement,
} from '@ui/MarkdownEditor/types';

import TableCell from '../TableCell';
import { isTableCell, type as tableCellType } from '../TableCell/shared';
import { type as tableHeaderType } from '../TableHeader/shared';
import TableRow from '../TableRow';
import { type as tableRowType } from '../TableRow/shared';

import { type, defaultTable, isTable, isTableEntry, isTableRow, isTableRowEntry } from './shared';

export const insertTable = (editor: Editor) => {
  const tableEntry = Editor.above(editor, { match: n => Editor.isBlock(editor, n as ReadmeElement) });
  if (!tableEntry) {
    // eslint-disable-next-line no-console
    console.warn("Couldn't find a block!?");
    return;
  }

  const [node, path] = tableEntry;
  const at = isEqual(emptyNode(), node) ? path : Path.next(path);

  Editor.withoutNormalizing(editor, () => {
    Transforms.insertNodes(editor, defaultTable(), { at, select: true });
    Transforms.select(editor, Editor.start(editor, at));
  });
};

export const addOrRemove = (
  editor: Editor,
  path: Path,
  { isColumn, remove }: { isColumn?: boolean; remove?: boolean } = {},
) => {
  const tableEntry = Editor.above(editor, { at: path, match: n => isTable(n) });
  if (!isTableEntry(tableEntry)) return;
  const [table, tablePath] = tableEntry;

  if (isColumn) {
    const currentCell = path[path.length - 1];

    if (remove && table.children[0].children.length === 1) {
      Transforms.removeNodes(editor, { at: tablePath });
      return;
    }

    table.children.forEach((_, rowcurrentCell: number) => {
      const someRowPath = [...tablePath, rowcurrentCell];

      if (remove) {
        Transforms.removeNodes(editor, {
          at: [...someRowPath, currentCell],
        });
      } else {
        Editor.withoutNormalizing(editor, () => {
          Transforms.insertNodes(editor, emptyNode({ align: 'left', type: TableCell.type as 'table-cell' }), {
            at: [...someRowPath, currentCell + 1],
          });
        });
      }
    });

    const align = remove
      ? table.align.filter((_: $TSFixMe, alignIndex: number) => alignIndex !== currentCell)
      : [...table.align.slice(0, currentCell + 1), 'left', ...table.align.slice(currentCell + 1)];
    Transforms.setNodes(editor, { align: align as TableAlignment[] }, { at: tablePath });
  } else {
    const currentNode = Node.get(editor, path);
    const rowEntry =
      Editor.above(editor, { at: path, match: isTableRow }) ||
      (isTableRow(currentNode) && [currentNode, path]) ||
      undefined;
    if (!isTableRowEntry(rowEntry)) return;

    const [row, rowPath] = rowEntry;
    const newRowPath = Path.next(rowPath);

    if (remove) {
      const at = table.children.length === 1 ? tablePath : Path.parent(path);
      Transforms.removeNodes(editor, { at });
    } else {
      Editor.withoutNormalizing(editor, () => {
        const children = row.children.map(() => emptyNode({ type: TableCell.type as 'table-cell' }));
        Transforms.insertNodes(editor, { type: TableRow.type, children } as TableRowElement, { at: newRowPath });
      });
    }
  }
};

export const insertRow = (...args: [editor: ReadmeEditor, path: Path]) =>
  addOrRemove(...args, { isColumn: false, remove: false });

export const move = (editor: Editor, path: Path, { isColumn, forward }: { forward: boolean; isColumn: boolean }) => {
  if (isColumn) {
    const tableEntry = Editor.above(editor, { at: path, match: n => isTable(n) });
    if (!isTableEntry(tableEntry)) return;

    const currentCell = path[path.length - 1];
    const [table, tablePath] = tableEntry;
    const newCellIdx = currentCell + (forward ? 1 : -1);

    table.children.forEach((_, rowIdx) => {
      const someRowPath = [...tablePath, rowIdx];
      Transforms.moveNodes(editor, { at: [...someRowPath, currentCell], to: [...someRowPath, newCellIdx] });
    });
  } else {
    const rowPath = path && Path.parent(path);

    Transforms.moveNodes(editor, { at: rowPath, to: forward ? Path.next(rowPath) : Path.previous(rowPath) });
  }
};

const colCount = (table: TableElement) => table.children[0].children.length;
const rowCount = (table: TableElement) => table.children.length;

const getCellSafe = (table: TableElement, y: number, x: number) => {
  try {
    return table.children[y].children[x].children;
  } catch (e) {
    return null;
  }
};

export const mergeTable = (
  editor: Editor,
  incomingTable: TableElement,
  { at }: { at?: BaseSelection } = { at: editor.selection },
) => {
  if (!at) {
    throw new Error('No selection?!');
  }
  const selectionStart = Range.start(at);

  const tableCellEntry = Editor.above(editor, { at: selectionStart, match: isTableCell });
  if (!tableCellEntry) {
    throw new Error('Not in a table cell?!');
  }
  const tableEntry = Editor.above(editor, { at: selectionStart, match: isTable });
  if (!tableEntry) {
    throw new Error('Not in a table?!');
  }

  const [table, tablePath] = tableEntry;
  const [, cellPath] = tableCellEntry;
  const [yStart, xStart] = cellPath.slice(-2);
  const columns = Math.max(colCount(table), colCount(incomingTable) + xStart);
  const rows = Math.max(rowCount(table), rowCount(incomingTable) + yStart);
  const align = new Array(columns).fill(0).map((_, index) => table.align[index] || TableAlignment.left);

  const newTable = {
    type,
    align,
    children: new Array(rows).fill(0).map((_, rowIndex) => {
      return {
        type: rowIndex === 0 ? tableHeaderType : tableRowType,
        children: new Array(columns).fill(0).map((__, colIndex) => {
          const children: TableCellElement['children'] = getCellSafe(
            incomingTable,
            rowIndex - yStart,
            colIndex - xStart,
          ) ||
            getCellSafe(table, rowIndex, colIndex) || [{ text: '' }];

          return {
            type: tableCellType as TableCellElement['type'],
            align: align[colIndex],
            children,
          };
        }),
      };
    }),
  };

  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, { at: tablePath });
    Transforms.insertNodes(editor, newTable as TableElement, { at: tablePath });

    const newSelection = {
      anchor: Editor.start(editor, [...tablePath, yStart, xStart]),
      focus: Editor.end(editor, [
        ...tablePath,
        yStart + rowCount(incomingTable) - 1,
        xStart + colCount(incomingTable) - 1,
      ]),
    };

    Transforms.select(editor, newSelection);
  });
};
