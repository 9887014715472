import type { CustomBlockMenuItem } from './types';
import type { Location } from 'slate';

import { Editor, Transforms } from 'slate';

import { isEmptyNode } from '@ui/MarkdownEditor/emptyNode';

import { JsxFlow, MenuHandle, ReusableContent } from '../blocks';

export const insertCustomBlock = (
  editor: Editor,
  block: CustomBlockMenuItem,
  { at }: { at: Location | null | undefined },
) => {
  if (!at) return;

  const { tag, type } = block;

  const entry = Editor.above(editor, { at, match: MenuHandle.is });
  let insertAt: Location | null | undefined = entry ? entry[1] : at;

  if (entry) {
    const blockEntry = Editor.above(editor, { at: entry[1], match: n => Editor.isBlock(editor, n) });

    if (blockEntry && isEmptyNode(blockEntry[0])) {
      Transforms.removeNodes(editor, { at: blockEntry[1] });
      insertAt = blockEntry[1];
    } else {
      insertAt = Editor.before(editor, Editor.start(editor, entry[1]));
      Transforms.removeNodes(editor, { at: entry[1] });
    }
  }

  if (type === 'component') {
    // Insert tag name with component syntax as a JSXFlow
    JsxFlow.operations.insertJsxFlowPlaceholder(editor, block, { at: insertAt });
  } else {
    ReusableContent.operations.insertReusableContent(editor, tag, { at: insertAt });
  }
};
