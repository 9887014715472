import type { TableHTMLAttributes } from 'react';
import type { Path } from 'slate';

import React, { useCallback } from 'react';
import { Editor, Range } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Button from '@ui/Button';
import TableToolbar, { TableToolbarProvider, useTableToolbar } from '@ui/MarkdownEditor/editor/TableToolbar';
import type { ReadmeEditor, ReadmeNode, TableElement } from '@ui/MarkdownEditor/types';

import { TableToolbarActionTypes } from '../../TableToolbar/useTableToolbar';
import { isTableCell, type as cellType } from '../TableCell/shared';

import { insertRow } from './operations';
import classes from './style.module.scss';

interface Props {
  attributes: TableHTMLAttributes<HTMLTableElement>;
  children: React.ReactNode;
  element: TableElement;
}

interface AddRowProps {
  editor: ReadmeEditor;
  path: Path;
}

const AddRow = ({ editor, path }: AddRowProps) => {
  const addRow = useCallback(() => {
    insertRow(editor, path);
  }, [editor, path]);

  return (
    <Button className={classes['Table-Add']} contentEditable={false} kind="secondary" onClick={addRow} size="sm" text>
      <i className="icon-plus1" />
      Add Row
    </Button>
  );
};

const Table = ({ attributes, children, element }: Props) => {
  const editor = useSlateStatic();
  const [, dispatch] = useTableToolbar();
  const [head, ...body] = children as Node[];
  const headPath = ReactEditor.findPath(editor, element.children[0]);

  const onPointerLeave = useCallback(
    event => {
      if (document.elementsFromPoint(event.clientX, event.clientY).find(e => (e as HTMLElement).dataset.tableToolbar)) {
        return;
      }

      dispatch({ type: TableToolbarActionTypes.Close });
    },
    [dispatch],
  );

  const onPointerOver = useCallback(
    event => {
      if (editor.selection && Range.isExpanded(editor.selection)) {
        onPointerLeave(event);
        return;
      }

      const node = ReactEditor.toSlateNode(editor, event.target);
      let path = ReactEditor.findPath(editor, node);

      if (!('type' in node) || node.type !== cellType) {
        const cellEntry = Editor.above(editor, { at: path, match: (n: ReadmeNode) => isTableCell(n) });
        if (!cellEntry) return;
        [, path] = cellEntry;
      }

      dispatch({ type: TableToolbarActionTypes.SetPath, path });
    },
    [dispatch, editor, onPointerLeave],
  );

  return (
    <>
      <div className="rdmd-table" {...attributes}>
        <div className="rdmd-table-inner">
          <table className={classes.Table} onPointerLeave={onPointerLeave} onPointerOver={onPointerOver}>
            {head}
            {body.length > 0 && <tbody>{body}</tbody>}
          </table>
          {body.length === 0 && <AddRow editor={editor} path={headPath} />}
        </div>
      </div>
      <TableToolbar />
    </>
  );
};

const TableContainer = (props: Props) => {
  return (
    <TableToolbarProvider>
      <Table {...props} />
    </TableToolbarProvider>
  );
};

export default TableContainer;
