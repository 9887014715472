import React from 'react';

import type { HTTPError } from '@core/utils/types/errors';

import Alert from '@ui/Alert';

interface Props {
  apiError?: HTTPError<{ detail: string }> | null;
  body?: string | null;
}

interface State {
  error?: Error;
}

class ErrorBoundary extends React.Component<Props, State> {
  state: State = {};

  static getDerivedStateFromError(error) {
    return { error };
  }

  fullMessage() {
    if (this.state.error) {
      return <pre>{this.state.error.stack}</pre>;
    } else if (this.props.apiError) {
      return <pre>{this.props.apiError.info?.detail}</pre>;
    }

    return '';
  }

  componentDidUpdate(prevProps: Props) {
    if (this.state.error && ('body' in prevProps && prevProps.body) !== ('body' in this.props && this.props.body)) {
      this.setState({ error: undefined });
    }
  }

  render() {
    const { error } = this.state;
    const { children, apiError } = this.props;

    if (error || apiError) {
      return <Alert subtitle={this.fullMessage()} title="The component preview encountered an error!" type="danger" />;
    }

    return children;
  }
}

export default ErrorBoundary;
