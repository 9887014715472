import type { BaseRange } from 'slate';

import React, { useCallback, useEffect } from 'react';
import { Editor, Node, Transforms } from 'slate';
import { useSlateStatic, type RenderElementProps, ReactEditor, useSelected } from 'slate-react';

import { MenuActionTypes, MenuHandleTypes } from '@ui/MarkdownEditor/enums';
import type { GlossaryTerm, MenuHandle } from '@ui/MarkdownEditor/types';
import useClassName from '@ui/MarkdownEditor/useClassName';
import Tooltip from '@ui/Tooltip';

import { useCustomBlocksMenu } from '../../CustomBlocksMenu';
import { isMenuHandle, type } from '../MenuHandle/shared';

import classes from './style.module.scss';

interface Props extends RenderElementProps {
  element: GlossaryTerm;
}

const openGlossaryMenu = (editor: Editor, element: GlossaryTerm, ref: React.RefObject<HTMLSpanElement>) => {
  const [, dispatch] = editor.customBlocksMenu;

  const rangeRef = Editor.rangeRef(editor, Editor.range(editor, ReactEditor.findPath(editor, element)));
  if (!rangeRef.current) return;

  Transforms.wrapNodes(editor, { type, menuType: MenuHandleTypes.glossary } as MenuHandle, {
    at: rangeRef.current,
    split: true,
  });

  dispatch({ type: MenuActionTypes.init, payload: { rangeRef, menuType: MenuHandleTypes.glossary } });
  dispatch({ type: MenuActionTypes.open, payload: { target: ref } });

  Transforms.select(editor, {
    anchor: { path: rangeRef.current.anchor.path, offset: rangeRef.current.anchor.offset },
    focus: { path: rangeRef.current.focus.path, offset: rangeRef.current.focus.offset },
  });
};

const GlossaryTerm = ({ attributes, children, element }: Props) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const className = useClassName('GlossaryItem-trigger', classes.GlossaryTerm);
  const [{ glossaryTerms, open, target, rangeRef }, dispatch] = useCustomBlocksMenu();
  const name = Node.string(element);
  const selectedTerm = glossaryTerms.find(term => term.term.toLowerCase() === name.toLowerCase());

  const openMenu = useCallback(
    event => {
      event.preventDefault();
      openGlossaryMenu(editor, element, attributes.ref);
    },
    [attributes.ref, editor, element],
  );

  useEffect(() => {
    if (open && target?.current === attributes?.ref?.current) {
      dispatch({ type: MenuActionTypes.search, payload: name });
    }
  }, [attributes?.ref, dispatch, name, open, target]);

  useEffect(() => {
    if (open && !selected && target?.current === attributes?.ref?.current && rangeRef?.current) {
      const entry = Editor.above(editor, { at: rangeRef.current as BaseRange, match: isMenuHandle });
      if (entry) Transforms.unwrapNodes(editor, { at: entry[1] });

      dispatch({ type: MenuActionTypes.close });
    }
  }, [attributes?.ref, dispatch, editor, open, rangeRef, selected, target]);

  const GlossaryTooltip = () => (
    <>
      <strong>
        <em>{name}</em>
      </strong>
      {!!selectedTerm?.definition && <span> - {selectedTerm.definition}</span>}
    </>
  );

  return open ? (
    <span {...attributes} className={className} onClick={openMenu} role="none" spellCheck="false">
      {children}
    </span>
  ) : (
    <Tooltip arrow={false} content={<GlossaryTooltip />} offset={[0, 5]} placement="bottom-start">
      <span {...attributes} className={className} onClick={openMenu} role="none" spellCheck="false">
        {children}
      </span>
    </Tooltip>
  );
};

export default GlossaryTerm;
