import { Editor, Node, Text, Transforms } from 'slate';

import { getNode } from '@ui/MarkdownEditor/editor/parser';
import type { BaseMdNode, Normalizer } from '@ui/MarkdownEditor/types';

import { fromMdNode, isReusableContent, type } from './shared';

// eslint-disable-next-line consistent-return
const parseNewReusableContent: Normalizer = next => (editor, nodeEntry) => {
  if (editor.props.disallowCustomBlocks) return next();

  const [node, path] = nodeEntry;
  if (!Text.isText(node) || Editor.above(editor, { at: path, match: n => isReusableContent(n) })) return next();

  const string = Node.string(node);
  // @perf: we can short circuit the deserializer with a quick regex
  // (check it out here: https://regexr.com/7n0ql)
  if (!string.match(/^<[A-Z]\S+\s*\/>$/)) return next();

  let mdast;

  try {
    mdast = editor.deserialize(string);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return next();
  }

  if (!mdast) return next();
  if (Array.isArray(mdast)) mdast = { children: mdast };

  const reusableContent = getNode(mdast, (n: BaseMdNode) => 'type' in n && n.type === type);
  if (!reusableContent) return next();

  const [, at] = Editor.above(editor, { at: path, match: n => Editor.isBlock(editor, n) }) || [];
  if (!at) return next();

  Transforms.removeNodes(editor, { at });
  Transforms.insertNodes(editor, fromMdNode(reusableContent, { renderingLibrary: editor.renderingLibrary }), {
    at,
    select: false,
  });
};

export default [parseNewReusableContent];
