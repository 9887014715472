import { useSlateStatic } from 'slate-react';

import classes from '@ui/MarkdownEditor/style.module.scss';
import type { ReadmeElement } from '@ui/MarkdownEditor/types';

import { useIsWholeBlockSelected } from './hooks/useIsWholeBlockSelected';

const useSelectedClassName = (element: ReadmeElement) => {
  const editor = useSlateStatic();
  const className = useIsWholeBlockSelected(editor, element) ? classes.selected : null;

  return className;
};

export default useSelectedClassName;
