import type { HtmlBlock, HtmlMdNode } from '@ui/MarkdownEditor/types';

import { type, rdmdType } from './shared';

export const deserialize = (node: HtmlMdNode): HtmlBlock => {
  let runScripts: boolean | undefined;
  let html = '';
  let flow = false;

  if (node.type === rdmdType) {
    runScripts = !!node.data.hProperties.runScripts;
    html = node.data.hProperties.html;
  } else {
    html = node.value;
    flow = true;
  }

  return {
    type,
    runScripts,
    ...(flow && { flow }),
    value: html,
    children: [{ text: '' }],
  } as HtmlBlock;
};

export const serialize = ({ flow, runScripts, value }: HtmlBlock): HtmlMdNode => {
  return flow
    ? {
        type,
        value,
      }
    : ({
        type: rdmdType,
        children: [{ type: 'text', value }],
        data: {
          hName: rdmdType,
          hProperties: {
            ...(runScripts && { runScripts }),
            html: value,
          },
        },
      } as HtmlMdNode);
};
