import { getNodes } from '@ui/MarkdownEditor/editor/parser';

import { type as htmlType } from '../Html/shared';
import { isParagraph, type as paragraphType } from '../Paragraph/shared';

import { type, rdmdHeaderType, rdmdType } from './shared';

const rxEscapedPipe = /\\\|/g;
const rxPipe = /([^\\])\|/g;

export const deserialize = ({ position, ...node }, deeper) => {
  // Find and replace all escaped pipes and unescape them.
  [...getNodes(node, n => n.type === 'inlineCode' && rxEscapedPipe.test(n.value))].forEach(n => {
    n.value = n.value.replace(rxEscapedPipe, '|');
  });
  [...getNodes(node, n => n.type === 'escape' && n.value === '|')].forEach(n => {
    n.type = 'text';
  });

  let children = deeper(node);

  // @perf: filling empty cells can trigger a normalize cycle. In large tables, this can be very expensive.
  if (children.length === 0) {
    children = [{ text: '' }];
  } else if (children.length === 1 && isParagraph(children[0])) {
    children = children[0].children;
  }

  return {
    ...node,
    type,
    children,
  };
};

export const serialize = ({ align, header, ...node }, deeper, { renderingLibrary }) => {
  let children = deeper(node);

  // Find and replace all unescaped pipes inside code blocks and escape them.
  // This is not symmetrical with `deserialize`, because RDMD will escape the
  // plain text pipes for us. The fact that we escape them can be considered a
  // UX convenience.
  [...getNodes({ children }, n => n.type === 'inlineCode' && rxPipe.test(n.value))].forEach(n => {
    n.value = n.value.replace(rxPipe, '$1\\|');
  });

  if (!('mdx' in renderingLibrary)) {
    children = children.flatMap((child, index) => {
      return child.type === paragraphType && index < children.length - 1
        ? [...child.children, { type: 'break' }, { type: 'break' }]
        : // @note: This is a hack to preserve newlines after html blocks in table
          // cells. Ideally we'd update @readme/markdown, but I don't want to make
          // changes to the old version.
          child.type === htmlType && index < children.length - 1
          ? [child, { type: 'break' }, { type: 'break' }]
          : child;
    });
  }

  return {
    ...node,
    type: header ? rdmdHeaderType : rdmdType,
    children,
  };
};
