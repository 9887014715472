/* eslint-disable consistent-return */
import { Editor, Node, Path, Text, Transforms } from 'slate';

import type {
  BlockquoteBlock,
  CodeEditorElement,
  ListItemElement,
  Normalizer,
  TableCellElement,
} from '@ui/MarkdownEditor/types';

import { isBlockquote } from '../Blockquote/shared';
import { isJsxComment } from '../JsxComment/shared';
import { isListItem } from '../ListItem/shared';
import { isParagraph } from '../Paragraph/shared';
import { isTableCell } from '../TableCell/shared';

import { insertJsxFlow } from './operations';
import { isJsxFlowElement, startingTagRegex } from './shared';

const isJsxFlowParent = (node: Node): node is BlockquoteBlock | Editor | ListItemElement | TableCellElement =>
  !![Editor.isEditor, isBlockquote, isListItem, isTableCell].find(is => is(node));

const convertToJsxFlowElement: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!editor.props.useMDX) return next();
    if (!Text.isText(node)) return next();
    if (Editor.above(editor, { at: path, match: isJsxComment })) return next();

    const match = node.text.match(startingTagRegex);
    if (!match || typeof match.index === 'undefined') return next();

    const value = node.text.slice(match.index);
    const at = {
      anchor: { path, offset: match.index },
      focus: { path, offset: node.text.length },
    };

    insertJsxFlow(editor, value, { at });
  };

const convertFromEsm: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!editor.props.useMDX) return next();
    if (!(isParagraph(node) && path.length === 1)) return next();

    const string = Node.string(node);
    if (!string.match(/^export /)) return next();

    insertJsxFlow(editor, string, { at: Editor.range(editor, path) });
  };

const unwrapJsxFlowElement: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!editor.props.useMDX) return next();
    if (!isJsxFlowElement(node)) return next();

    const parentEntry = Editor.parent(editor, path);
    if (!parentEntry) return next();

    const [parent, parentPath] = parentEntry;
    const fine = node.value.match(/^export/) ? Editor.isEditor(parent) : isJsxFlowParent(parent);
    if (fine) return next();

    Transforms.unwrapNodes(editor, {
      at: path,
      match: (_, subPath) => subPath.length === parentPath.length,
      split: true,
    });
  };

const mergeNodes: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!editor.props.useMDX) return next();
    if (!isJsxFlowParent(node)) return next();

    const index = node.children
      .slice(0, -1)
      .findIndex((child, idx) => isJsxFlowElement(child) && isJsxFlowElement(node.children[idx + 1]));
    if (index < 0) return next();

    const [firstChild, secondChild] = [node.children[index], node.children[index + 1]];
    if (!isJsxFlowElement(firstChild) || !isJsxFlowElement(secondChild)) return next();

    const value = `${firstChild.value}\n\n${secondChild.value}`;
    let { selection } = firstChild;

    if (!selection) {
      selection = secondChild.selection;

      if (selection) {
        const offset = firstChild.value.split('\n').length + 1;
        selection = selection.map(({ ch, line }) => ({
          ch,
          line: line + offset,
        })) as CodeEditorElement['selection'];
      }
    }

    const updates = { value, ...(selection && { selection }) };

    Editor.withoutNormalizing(editor, () => {
      Transforms.removeNodes(editor, { at: [...path, index + 1] });
      Transforms.setNodes(editor, updates, { at: [...path, index] });

      if (editor.selection) {
        if (Path.isAfter(editor.selection.anchor.path, [...path, index])) {
          Transforms.move(editor, { unit: 'line', edge: 'anchor', reverse: true });
        }
        if (Path.isAfter(editor.selection.focus.path, [...path, index])) {
          Transforms.move(editor, { unit: 'line', edge: 'focus', reverse: true });
        }
      }
    });
  };

export default [convertToJsxFlowElement, convertFromEsm, unwrapJsxFlowElement, mergeNodes];
