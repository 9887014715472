import type { RenderLeafProps } from 'slate-react';

import React, { useEffect, useRef } from 'react';
import { useSlateStatic, useSelected } from 'slate-react';

import { MenuActionTypes } from '@ui/MarkdownEditor/enums';

import useSlashMenu from './useSlashMenu';

type Props = RenderLeafProps;

const SlashMenuLeaf = ({ children, leaf, ...props }: Props) => {
  const editor = useSlateStatic();
  const [{ open, rangeRef }, dispatch] = useSlashMenu();
  const ref = useRef(null);
  const selected = useSelected();

  useEffect(() => {
    // The menu will originally get an 'init' action, and for it to be really
    // open, this leaf needs to submit it's ref.
    if (rangeRef && !open && leaf.text.startsWith('/') && selected) {
      dispatch({ type: MenuActionTypes.open, payload: { target: ref } });
    }

    return () => {
      // This is the case for when you delete all the text including the `\`
      if (open && !leaf.text.startsWith('/')) {
        dispatch({ type: MenuActionTypes.close });
      }
    };
  }, [dispatch, editor, leaf, leaf.text, open, rangeRef, selected]);

  useEffect(() => {
    dispatch({ type: MenuActionTypes.search, payload: leaf.text.substring(1) });
  }, [dispatch, leaf]);

  useEffect(() => {
    // If you click away from the node, close it.
    if (!selected) {
      dispatch({ type: MenuActionTypes.close });
    }
  }, [dispatch, selected]);

  useEffect(() => {
    return () => dispatch({ type: MenuActionTypes.close });
  }, [dispatch]);

  return (
    <span {...props} ref={ref}>
      {children}
    </span>
  );
};

export default SlashMenuLeaf;
