import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import Confetto, { maxTime } from './Confetto';

const Confetti = ({ count = 20, continuous = false, kind, onEnd, x, y }) => {
  const [start, setStart] = useState(Date.now());
  const [time, setTime] = useState(Date.now());

  const update = useCallback(() => {
    const current = Date.now();
    if (current - start > maxTime) {
      if (continuous) setTimeout(() => setStart(Date.now()), 1000);
      if (onEnd) onEnd();
      return;
    }

    setTime(current - start);
    requestAnimationFrame(update);
  }, [continuous, onEnd, start]);
  useEffect(update, [update]);

  return Array.from({ length: count }).map((n, i) => {
    return <Confetto key={i} kind={kind} start={start} time={time} x={x} y={y} />;
  });
};

Confetti.propTypes = {
  continuous: PropTypes.bool,
  count: PropTypes.number,
  kind: PropTypes.oneOf(['red', 'yellow', 'green', 'blue', 'purple', '']),
  onEnd: PropTypes.func,
  x: PropTypes.number,
  y: PropTypes.number,
};

export const confetti = ({ kind, x, y }) => {
  const div = document.createElement('div');

  document.body.appendChild(div);

  const cleanup = () => {
    ReactDOM.unmountComponentAtNode(div);
    div.remove();
  };

  ReactDOM.render(<Confetti kind={kind} onEnd={cleanup} x={x} y={y} />, div);
};

export default Confetti;
