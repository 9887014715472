import { Editor, Point, Range } from 'slate';
import { ReactEditor, useSelected } from 'slate-react';

import type { ReadmeElement } from '@ui/MarkdownEditor/types';

export const useIsWholeBlockSelected = (editor: Editor, element: ReadmeElement) => {
  const selected = useSelected();
  if (!selected || !editor.selection) return false;

  const path = ReactEditor.findPath(editor, element);
  const elementRange = Editor.range(editor, path);

  return (
    Point.compare(Range.start(elementRange), Range.start(editor.selection)) >= 0 &&
    Point.compare(Range.end(elementRange), Range.end(editor.selection)) <= 0
  );
};
