import type { Descendant } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type {
  Deserializer,
  FormattedText,
  HtmlBareMdNode,
  HtmlBlock,
  ParagraphElement,
  ReadmeInline,
} from '@ui/MarkdownEditor/types';

import { deserialize as htmlBlockDeserialize } from '../Html/serialize';
import { type as paragraphType } from '../Paragraph/shared';

import { isHtmlComment } from './shared';

const getContentFromValue = (value: Descendant[]) => {
  if (value.length === 1 && 'type' in value[0] && value[0].type === paragraphType && 'children' in value[0]) {
    return value[0].children;
  }

  return value;
};

const parseHtmlComments = (
  node: HtmlBareMdNode,
  deserialize: (doc: string) => Descendant[],
  { isBlock }: { isBlock: boolean },
): Descendant[] | ParagraphElement => {
  const string = node.value;
  const endCommentIndex = string.indexOf('-->');
  /*
   * The editor will often escape the closing comment tag, for example:
   *
   * ```
   * <!--
   * # Content
   * -->
   * ```
   *
   * will get saved as:
   *
   * ```
   * <!--
   * # Content
   * \-->
   * ```
   *
   * I believe this is to prevent parsing it as a list item?
   */
  const innerContent = string.slice(4, endCommentIndex).replace(/\n\\$/, '');
  const innerFragment = deserialize(innerContent);
  const looksLikeInlineContent =
    string.indexOf('\n') === -1 ||
    (innerFragment.length === 1 &&
      'type' in innerFragment[0] &&
      innerFragment[0].type === paragraphType &&
      'children' in innerFragment[0]);

  if (looksLikeInlineContent) {
    const fragment: (FormattedText | ReadmeInline)[] = [
      { text: '<!--' },
      ...(getContentFromValue(innerFragment) as ReadmeInline[]),
      /*
       * This is a weird quirk of comments, whatever comes after them is not
       * parsed as markdown?!
       */
      { text: string.slice(endCommentIndex - 1) },
    ];

    return isBlock ? emptyNode<ParagraphElement>({ children: fragment as (FormattedText | ReadmeInline)[] }) : fragment;
  }

  return [
    emptyNode<ParagraphElement>({
      children: [{ text: '<!--' }],
    }),
    ...getContentFromValue(innerFragment),
    emptyNode<ParagraphElement>({
      /*
       * This is a weird quirk of comments, whatever comes after them is not
       * parsed as markdown?!
       */
      children: [{ text: string.slice(endCommentIndex) }],
    }),
  ];
};

const isBreak = (node: HtmlBareMdNode) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(node.value, 'text/html');

  return dom.body.children.length === 1 && dom.body.children[0].tagName === 'BR';
};

export const deserialize: Deserializer<
  HtmlBareMdNode,
  Descendant[] | FormattedText | HtmlBlock | HTMLElement | ParagraphElement
> = (node: HtmlBareMdNode, _deeper, { deserialize: deserializeDoc }) => {
  const isBlock = !!('block' in node && node.block);
  const isComment = isHtmlComment(node);

  if (isComment) {
    return parseHtmlComments(node, deserializeDoc, { isBlock });
  }

  if (isBlock) {
    return isBreak(node) ? emptyNode<ParagraphElement>() : htmlBlockDeserialize(node);
  }

  return {
    text: node.value,
  };
};
