import type { HTMLAttributes } from 'react';

import React from 'react';

import useSelectedClassName from '@ui/MarkdownEditor/editor/useSelectedClassName';
import type { TableRowElement } from '@ui/MarkdownEditor/types';

interface Props {
  attributes: HTMLAttributes<HTMLElement>;
  children: React.ReactNode;
  element: TableRowElement;
}

const TableRow = ({ attributes, children, element }: Props) => {
  return (
    <tr {...attributes} className={useSelectedClassName(element)}>
      {children}
    </tr>
  );
};

export default TableRow;
