import type { CustomBlockMenuItem } from './types';

export const sampleData: CustomBlockMenuItem[] = [
  {
    type: 'content',
    source: '# Testing!',
    name: 'Testing',
    tag: 'Testing',
    updated_at: '2023-09-22T18:07:41.048Z',
  },
  {
    type: 'content',
    source: '# I am Reusable!',
    name: 'Reusable',
    tag: 'Reusable',
    updated_at: '2023-09-22T18:07:41.048Z',
  },
  {
    type: 'content',
    source: '# Hey there',
    name: 'Hello',
    tag: 'hello',
    updated_at: '2023-09-22T18:07:41.048Z',
  },
  {
    type: 'component',
    source: '# Testing!',
    name: 'Component',
    tag: 'component',
    updated_at: '2023-09-22T18:07:41.048Z',
  },
  {
    type: 'component',
    source: '# I am Reusable!',
    name: 'Another Component',
    tag: 'another-component',
    updated_at: '2023-09-22T18:07:41.048Z',
  },
  {
    type: 'component',
    source: '# Hey there',
    name: 'Hello Component',
    tag: 'hello-component',
    updated_at: '2023-09-22T18:07:41.048Z',
  },
];
