import type { HTMLAttributes } from 'react';

import isEqual from 'lodash/isEqual';
import React, { useCallback } from 'react';
import { useSlateStatic } from 'slate-react';

import Avatar from '@ui/Avatar';
import Box from '@ui/Box';
import Button from '@ui/Button';
import SelectionWrapper from '@ui/MarkdownEditor/editor/SelectionWrapper';
import type { RecipeBlock } from '@ui/MarkdownEditor/types';

import EmptyBlock from '../EmptyBlock';

import classes from './style.module.scss';

interface Props {
  attributes: HTMLAttributes<HTMLElement>;
  children: React.ReactNode;
  element: RecipeBlock;
}

const Recipe = ({ attributes, children, element }: Props) => {
  const editor = useSlateStatic();
  const [, { open }] = editor.recipeMenu;
  const { backgroundColor, emoji, link, title, slug } = element;
  const recipeSelected = !!title;
  const recipeLink = editor.props.superhub ? `/update/recipes/${slug}` : link;

  const openRecipeMenu = useCallback(
    event => {
      // If we're dragging selection across the recipe in any way, don't open the menu
      // @note: I'd rather use acrossBlocks here, but technically if we've only selected
      // the line just above the image, we're not across blocks when we cursor into the image
      if (editor.selection && !isEqual(editor.selection.anchor, editor.selection.focus)) return;

      event.preventDefault();
      open(element);
    },
    [editor, open, element],
  );

  return (
    <SelectionWrapper contentEditable={false} element={element} {...attributes}>
      {recipeSelected ? (
        <Box className={classes.Recipe} data-testid="selected-recipe-component" kind="rule">
          <Button
            className={classes['Recipe-Settings']}
            data-testid="recipe-menu-button"
            kind="minimum"
            onClick={openRecipeMenu}
            outline
            size="sm"
          >
            <i className="icon-settings" />
          </Button>
          <div className={classes['Recipe-Tile']}>
            {!!emoji && (
              <Avatar className={classes['Recipe-Tile-Avatar']} hexColor={backgroundColor} size="sm">
                {emoji}
              </Avatar>
            )}
            <div className={classes['Recipe-Tile-Text']}>
              <div className={classes['Recipe-Tile-Text-Title']}>{title}</div>
              <a className={classes['Recipe-Tile-Text-Action']} href={recipeLink} rel="noreferrer" target="_blank">
                Open Recipe
                <i className={[`${classes['Recipe-Tile-Text-Action-Arrow']} icon-arrow-up-right`].join(' ')} />
              </a>
            </div>
          </div>
          {children}
        </Box>
      ) : (
        <div {...attributes} contentEditable={false}>
          <EmptyBlock icon="icon-recipes" onClick={openRecipeMenu} role="button" text="Choose Recipe" />
          {children}
        </div>
      )}
    </SelectionWrapper>
  );
};

export default Recipe;
