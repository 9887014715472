import Component from './Component';
import normalizeNode from './normalizeNode';
import { serialize } from './serialize';
import { type, isMenuHandle, prefixes } from './shared';

export default {
  Component,
  is: isMenuHandle,
  isMenuHandle,
  isInline: true,
  normalizeNode,
  prefixes,
  serialize,
  type,
};
