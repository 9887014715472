import type { RenderingLibrary } from '../RenderingLibraryProvider';
import type { Node } from 'mdast';

import * as RDMD from '@readme/markdown';
import { setAutoFreeze } from 'immer';
import { Editor, createEditor } from 'slate';

import type { Components, Props, ReadmeBlock, ReadmeEditor, ReusableContent } from '@ui/MarkdownEditor/types';

import emptyNode from '../../emptyNode';
import _log from '../log';
import { deserializer } from '../parser';
import preNormalize from '../utils/preNormalize';
import withReadme from '../withReadme';

import mdastToSlate from './mdastToSlate';

const log = _log.extend('deserialize');

// XXX: I want to be able to run core slate normalizations against fragments so
// when we deserialize from markdown we can normalize before inserting or
// comparing to slate values.
//
// https://docs.slatejs.org/concepts/10-normalizing#built-in-constraints
const withTemporaryEditor = (value: ReadmeBlock[], opts: Opts, fn: (editor: ReadmeEditor) => void) => {
  const editor = withReadme(createEditor(), { useMDX: opts.useMDX }, { renderingLibrary: opts.renderingLibrary });

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  editor.deserialize = (doc, _opts) => deserialize(doc, { ...opts, ..._opts });
  editor.children = value;

  setAutoFreeze(false);
  fn(editor);
  setAutoFreeze(true);

  return editor.children as ReadmeBlock[];
};

// @note: stolen directly from @readmeio/markdown index.js
// @XXX: Must keep it in sync!
const normalizeMagicBlocks = md => md.replace(/^\[block:/gm, '\n[block:').replace(/^\[\/block\]/gm, '[/block]\n');

type Opts = Pick<Props, 'useMDX'> & {
  components?: Components;
  mdast?: Node;
  renderingLibrary?: RenderingLibrary;
  reusableContent?: ReusableContent;
};

const deserialize = (doc = '', opts: Opts = {}): ReadmeBlock[] => {
  let { mdast = {} } = opts;
  opts.useMDX ??= false;
  opts.renderingLibrary ??= RDMD;
  opts.reusableContent ??= { tags: {} };
  opts.components ??= {};
  const { components, reusableContent, renderingLibrary, useMDX } = opts;
  const whitespaceNormalized = normalizeMagicBlocks(doc.trimEnd());

  if (!Object.keys(mdast).length) {
    mdast = useMDX
      ? renderingLibrary.mdast(doc, { components })
      : deserializer(whitespaceNormalized, {
          normalize: false,
          reusableContent,
          settings: { position: true },
        });
  }

  // @note: If the mdast is null, let's fallback to at least a valid doc.
  // Strangely an empty string '', will trigger null?!
  if (!mdast) return [emptyNode()];

  const value = mdastToSlate(mdast, whitespaceNormalized, {
    ...opts,
    deserialize: (str: string) => deserialize(str, opts),
  });

  log({ value });

  return withTemporaryEditor(value, { useMDX, renderingLibrary }, (editor: ReadmeEditor) => {
    preNormalize(editor);
    Editor.normalize(editor, { force: true });
  });
};

export default deserialize;
