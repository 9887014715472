import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import useClassName from '@ui/MarkdownEditor/useClassName';
import MenuItem from '@ui/Menu/Item';
import Owlmoji from '@ui/Owlmoji';

import classes from './style.module.scss';

const _EmojiMenuItems = ({ name, onClick: onClickHandler, selected, style }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onClick = useCallback(() => onClickHandler({ name }), [name, onClickHandler]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const className = useClassName(classes.EmojiMenuItem, selected && 'selected');

  return (
    <MenuItem
      key={name}
      aria-current={selected}
      className={className}
      data-name={name}
      onClick={onClick}
      role="menuitem"
      style={style}
    >
      <div className={classes['EmojiMenuItem-Container']}>
        <Owlmoji className={classes['EmojiMenuItem-Container-emoji']}>{name}</Owlmoji>
        <p className={classes['EmojiMenuItem-Container-name']}>{name}</p>
      </div>
    </MenuItem>
  );
};

const EmojiMenuItems = React.memo(_EmojiMenuItems);

_EmojiMenuItems.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default EmojiMenuItems;
