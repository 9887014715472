import * as rmdx from '@readme/mdx';

const escape = (value: string) => {
  let mdx = rmdx
    .mdx({ type: 'root', children: [{ type: 'paragraph', children: [{ type: 'text', value }] }] })
    .replaceAll(/(?<!\\)&#x20;/g, ' ');

  /*
   * @note: `rmdx.mdx` always ends with a newline. We should check to see if it
   * was added or not, and them possibly remove it.
   */
  if (!value.match(/\n$/)) {
    mdx = mdx.replace(/\n$/, '');
  }

  return mdx;
};

export default escape;
