import type { CustomBlockRenderRepresentationType } from '@readme/api/src/mappings/customblock/types';

import debounce from 'lodash/debounce';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import type { ProjectContextValue, VersionContextValue } from '@core/context';
import { ProjectContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useLocalStorage from '@core/hooks/useLocalStorage';
import { fetcher } from '@core/hooks/useReadmeApi';
import type { HTTPError } from '@core/utils/types/errors';

import Badge from '@ui/Badge';
import Button from '@ui/Button';
import CodeInput from '@ui/CodeInput';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuHeader, MenuItem } from '@ui/Menu';
import RDMD from '@ui/RDMD';
import ErrorBoundary from '@ui/RDMD/ErrorBoundary';
import { RHFGroup } from '@ui/RHF';
import Tooltip from '@ui/Tooltip';

import { useCustomBlockFormContext } from '../Context';

import { newStateExample } from './newStateExample';
import classes from './style.module.scss';

const VALIDATION_TIMEOUT_MS: number =
  (process.env.MDX_CHILD_PROCESS_TIMEOUT_MS && parseInt(process.env.MDX_CHILD_PROCESS_TIMEOUT_MS, 10)) || 3000;

export default function ComponentEditor() {
  const bem = useClassy(classes, 'ComponentEditor');
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { version } = useContext(VersionContext) as VersionContextValue;
  const { control } = useCustomBlockFormContext();
  const sourceValue = useWatch({ control, name: 'source' });
  const isNewComponent = window.location.href.match(/custom-components\/new/) !== null;
  const storage = useLocalStorage();
  const [currentField, setCurrentField] = useState(isNewComponent ? newStateExample : sourceValue);
  const [dehydrated, setDehydrated] = useState<string | null>(null);
  const [layout, setLayout] = useState(storage.getItem('layout') || 'col');
  const [theme, setTheme] = useState(storage.getItem('theme') || 'dark');
  const [error, setError] = useState<HTTPError<{ detail: string }> | null>(null);

  // locally store layout and theme preferences
  useEffect(() => {
    storage.setItem('layout', layout);
    storage.setItem('theme', theme);
  }, [layout, storage, theme]);

  const toggleLayout = () => {
    setLayout((prevLayout: string) => (prevLayout === 'row' ? 'col' : 'row'));
  };

  const toggleTheme = () => {
    setTheme((prevTheme: string) => (prevTheme === 'dark' ? 'light' : 'dark'));
  };

  const onFieldChange = (value: string) => {
    setCurrentField(value);
  };

  const debouncedValidation = useMemo(() => {
    return debounce(
      async (source, self) => {
        setError(null);

        if (!source) {
          return;
        }

        try {
          const tag = 'new';
          const response = await fetcher<CustomBlockRenderRepresentationType>(
            `/${project.subdomain}/api-next/v2/versions/${version}/custom_blocks/${tag}/render`,
            {
              body: JSON.stringify({ type: 'component', name: tag, source }),
              method: 'POST',
            },
          );

          setError(null);
          setDehydrated(response.data.dehydrated);
        } catch (e) {
          setError(e);
          setDehydrated(null);
        } finally {
          self.flush();
        }
      },
      VALIDATION_TIMEOUT_MS,
      { leading: true },
    );
  }, [project.subdomain, version]);

  useEffect(() => {
    debouncedValidation(currentField, debouncedValidation);

    return debouncedValidation.cancel;
  }, [debouncedValidation, debouncedValidation.cancel, currentField]);

  return (
    <Flex className={bem('&')} data-testid="ComponentEditor" gap={0} layout={layout}>
      <div className={bem('-inputWrapper')} data-label="index.mdx">
        {/* @note: 250000 is coming from the mdx-renderer: https://github.com/readmeio/mdx-renderer/commit/d8e81faadef3f1a32ca804abb04c3981585ed851#diff-8a8ae07582c9d433ec8c2e5c4310ff8901e604f4965c5b90a49117ad46c47595R65 */}
        <RHFGroup control={control} errorStyle="tooltip" maxLength={250000} name="source" required>
          {/*
           * @note: If you don't destructure `onChange` off of `field`,
           * somehow when it's expanded onto the props, it causes what appears
           * to be some re-rendering issues? 🤷
           */}
          {({ field: { onChange, ...field } }) => (
            <CodeInput
              {...field}
              className={bem('-codeInput')}
              initialValue={currentField}
              language="jsx"
              onChange={e => {
                onChange(e);
                onFieldChange(e);
              }}
              size="sm"
            />
          )}
        </RHFGroup>
      </div>
      <div className={bem('-preview', `-preview_${layout}`, `-preview_${theme}`)}>
        <Flex align="center" className={bem('-preview-header')} justify="between">
          <Flex align="center">
            <Badge allCaps circular={false} kind={theme === 'dark' ? 'dark' : 'light'}>
              Preview
            </Badge>
          </Flex>
          <Flex align="center" className={bem('-preview-header-controls')} gap={0}>
            <Tooltip asTitle content={theme === 'dark' ? 'Set Light Mode' : 'Set Dark Mode'} placement="bottom-end">
              <Button ghost kind="contrast" onClick={toggleTheme} size="sm">
                <Icon name={theme === 'dark' ? 'moon' : 'sun'} />
              </Button>
            </Tooltip>
            <Tooltip asTitle content={layout === 'row' ? 'Split Horizontal' : 'Split Vertical'} placement="bottom-end">
              <Button ghost kind="contrast" onClick={toggleLayout} size="sm">
                <Icon name={layout === 'row' ? 'columns-2' : 'rows-2'} />
              </Button>
            </Tooltip>
            <Tooltip asTitle content="Marketplace" placement="bottom-end">
              <Button
                ghost
                href="https://github.com/readmeio/marketplace"
                kind="contrast"
                rel="noreferrer"
                size="sm"
                target="_blank"
              >
                <Icon aria-label="Opens in a new tab" name="marketplace" />
              </Button>
            </Tooltip>
            <Dropdown sticky>
              <Button ghost kind="contrast" size="sm">
                <Icon name="help-circle" />
              </Button>
              <Menu>
                <MenuHeader>Resources</MenuHeader>
                <MenuItem
                  href="https://docs.readme.com/main/v3.0/docs/building-custom-mdx-components"
                  TagName="a"
                  target="_blank"
                >
                  <span>Building Components</span>
                </MenuItem>
                <MenuItem href="https://tailwindcss.com/docs/styling-with-utility-classes" TagName="a" target="_blank">
                  <span>Styling with Tailwind</span>
                </MenuItem>
              </Menu>
            </Dropdown>
          </Flex>
        </Flex>
        <Flex className={bem('-preview-render')} data-theme={theme}>
          <ErrorBoundary apiError={error} body={currentField}>
            {!!dehydrated && (
              <RDMD
                body={currentField}
                darkModeDataAttribute="data-theme"
                dehydrated={dehydrated}
                mdx
                opts={{ useTailwindRoot: true }}
                throwParseError
              />
            )}
          </ErrorBoundary>
        </Flex>
      </div>
    </Flex>
  );
}
