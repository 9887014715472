import type { Dispatch, ReactNode, RefObject } from 'react';
import type { RangeRef } from 'slate';

import fuzzysort from 'fuzzysort';
import React, { createContext, useContext, useReducer, useMemo } from 'react';
import { Editor } from 'slate';

import type { MenuActionTypes } from '@ui/MarkdownEditor/enums';

import { bounded } from '../utils';

import _commands from './commands';

interface InitAction {
  payload: { rangeRef: RangeRef };
  type: MenuActionTypes.init;
}

interface OpenAction {
  payload: {
    target: RefObject<HTMLElement>;
  };
  type: MenuActionTypes.open;
}

interface UpAction {
  type: MenuActionTypes.up;
}

interface DownAction {
  type: MenuActionTypes.down;
}

interface SearchAction {
  payload: string;
  type: MenuActionTypes.search;
}

interface CloseAction {
  type: MenuActionTypes.close;
}

type SlashMenuAction = CloseAction | DownAction | InitAction | OpenAction | SearchAction | UpAction;

interface Command {
  basic?: boolean;
  category: string;
  icon: string;
  name: string;
}

interface SlashMenuState {
  commands: Command[];
  filtered: Command[];
  open: boolean;
  rangeRef: RangeRef | null;
  search: string;
  selected: number;
  target: RefObject<HTMLElement> | null;
}

interface SlashMenuReducer {
  (state: SlashMenuState, action: SlashMenuAction): SlashMenuState;
}

interface Options {
  basic?: boolean;
  disallowHtmlBlocks?: boolean;
  disallowRecipes?: boolean;
  showCustomBlocks?: boolean;
  useMDX?: boolean;
}

const unfiltered = _commands as Command[];

const _initial: SlashMenuState = {
  commands: unfiltered,
  filtered: unfiltered,
  open: false,
  search: '',
  selected: 0,
  rangeRef: null,
  target: null,
};

const byScoreThenAlpha = (left: Fuzzysort.KeysResult<Command>, right: Fuzzysort.KeysResult<Command>) => {
  // right[0] or left[0] is null when we match against the category instead of the name
  if (!right[0] || !left[0]) {
    return 0;
  }
  const byScore = right[0].score - left[0].score;
  return byScore !== 0 ? byScore : left[0].target.localeCompare(right[0].target);
};

export const init = (editor: Editor, { distance = 1 } = {}) => {
  if (!editor.selection) return {};

  const rangeRef = Editor.rangeRef(editor, {
    anchor: {
      path: editor.selection.anchor.path,
      offset: editor.selection.anchor.offset - distance,
    },
    focus: editor.selection.focus,
  });

  return { type: 'init', payload: { rangeRef } };
};

const useSlashMenuReducer = ({ disallowHtmlBlocks, disallowRecipes, showCustomBlocks, useMDX, basic }: Options) => {
  const memoized: [SlashMenuReducer, SlashMenuState] = useMemo(() => {
    let commands = basic ? unfiltered.filter(c => c.basic) : [...unfiltered];
    commands = showCustomBlocks ? commands : commands.filter(c => c.category !== 'Reusable');
    commands = disallowHtmlBlocks ? commands.filter(c => c.name !== 'Custom HTML') : commands;
    commands = disallowRecipes ? commands.filter(c => c.name !== 'Recipe') : commands;
    commands = !useMDX ? commands.filter(c => c.category !== 'Components') : commands;
    commands = !useMDX ? commands.filter(c => c.name !== 'Mermaid') : commands;
    commands = useMDX
      ? commands.filter(c => c.name !== 'Image' && !(c.category === 'Embeds' && !c.name.includes(' Embed')))
      : commands.filter(c => c.name !== 'Image Block' && !c.name.includes(' Embed'));

    const initial = { ..._initial, commands };

    const reducer: SlashMenuReducer = (state, action) => {
      switch (action.type) {
        case 'init':
          return { ...initial, rangeRef: action.payload.rangeRef };
        case 'open':
          if (state.open) return state;
          return { ...state, target: action.payload.target, open: true };
        case 'up':
          return { ...state, selected: bounded(state.selected - 1, state.filtered.length) };
        case 'down':
          return { ...state, selected: bounded(state.selected + 1, state.filtered.length) };
        case 'search':
          return {
            ...state,
            search: action.payload,
            selected: 0,
            filtered: action.payload
              ? fuzzysort
                  .go(action.payload, state.commands, {
                    keys: ['name', 'category'],
                  })
                  // @ts-ignore
                  .sort(byScoreThenAlpha)
                  .map((r: Fuzzysort.KeysResult<Command>) => r.obj)
              : state.commands,
          };
        case 'close':
          state.rangeRef?.unref();
          return {
            ...state,
            open: false,
            target: null,
            rangeRef: null,
          };
        default:
          // eslint-disable-next-line no-console
          console.warn('Unknown action in useSlashMenu');
          return state;
      }
    };

    return [reducer, initial];
  }, [basic, disallowHtmlBlocks, disallowRecipes, showCustomBlocks, useMDX]);

  // @note: Much to my chagrin, the array's returned from react hooks are not
  // guaranteed to be stable. Sometimes I like to pass the whole tuple around,
  // but of course that means it changes every render. For my sanity, let's
  // memoize it.
  const [slashMenuState, dispatch] = useReducer(...memoized);
  return useMemo<[SlashMenuState, Dispatch<SlashMenuAction>]>(() => [slashMenuState, dispatch], [slashMenuState]);
};

const SlashMenuContext = createContext([_initial, () => {}] as [SlashMenuState, Dispatch<SlashMenuAction>]);

export const SlashMenuProvider = ({
  basic,
  children,
  disallowHtmlBlocks,
  disallowRecipes,
  showCustomBlocks,
  useMDX,
}: Options & {
  children: ReactNode;
}) => {
  const value = useSlashMenuReducer({ disallowHtmlBlocks, disallowRecipes, showCustomBlocks, useMDX, basic });

  return <SlashMenuContext.Provider value={value}>{children}</SlashMenuContext.Provider>;
};

export const useSlashMenu = () => useContext(SlashMenuContext);
export default useSlashMenu;
