import type { HTMLAttributes } from 'react';

import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Node } from 'slate';

import type { TableCellElement, TableHeaderCellElement } from '@ui/MarkdownEditor/types';
import useClassName from '@ui/MarkdownEditor/useClassName';

import useSelectedClassName from '../../useSelectedClassName';

import { isTableHeaderCell } from './shared';
import classes from './style.module.scss';

interface Props {
  attributes: HTMLAttributes<HTMLElement>;
  children: React.ReactNode;
  element: TableCellElement | TableHeaderCellElement;
}

const TableCell = ({ attributes, children, element }: Props) => {
  const selectedClassName = useSelectedClassName(element);

  const align = element.align ?? (isTableHeaderCell(element) ? ('center' as const) : ('left' as const));
  const style = { textAlign: align };
  const isCellEmpty = isEmpty(Node.string(element));
  const isHeaderCellEmpty = isTableHeaderCell(element) && isCellEmpty;

  const className = useClassName(
    classes.TableCell,
    isTableHeaderCell(element) && classes.TableCell_Header,
    !isCellEmpty && selectedClassName,
    ...(isHeaderCellEmpty ? [classes.TableCell_Placeholder, classes[`TableCell_Placeholder-${align}`]] : []),
  );

  return (
    <td {...attributes} className={className} style={style}>
      {children}
    </td>
  );
};

export default TableCell;
