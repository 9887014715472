/* eslint-disable consistent-return */
import type { HtmlBlock, Normalizer } from '../../../types';

import { Editor, Text, Transforms } from 'slate';

import { getNode } from '../../parser';
import { offsetToCodeEditorSelection, pointToOffset } from '../../utils';
import { isParagraph } from '../Paragraph/shared';

export const convertToHtml: Normalizer =
  next =>
  (editor, [node, path]) => {
    if ('mdx' in editor.renderingLibrary) return next();

    if (!Text.isText(node)) return next();
    // A quick short circuit to avoid running a full md parse.
    if (!node.text.match(/<.*>/)) return next();

    const blockEntry = Editor.above(editor, { at: path, match: n => Editor.isBlock(editor, n) });
    if (!blockEntry) return next();

    const string = editor.serialize({ type: 'root', children: blockEntry[0].children });
    if (!string.match(/\n/)) return next();

    const mdast = editor.renderingLibrary.mdast(string);
    if (!mdast) return next();

    const htmlNode = getNode(mdast, mdNode => mdNode.type === 'html' && mdNode.block);
    if (!htmlNode) return next();

    const selection = editor.selection;
    let startOffset = 0;
    let endOffset = string.length;
    if (selection) {
      startOffset = pointToOffset(blockEntry, selection.anchor);
      endOffset = pointToOffset(blockEntry, selection.focus);
    }

    const codeEditorSelection: HtmlBlock['selection'] = [
      offsetToCodeEditorSelection(startOffset, string),
      offsetToCodeEditorSelection(endOffset, string),
    ];

    const htmlElement: HtmlBlock = {
      type: 'html',
      value: htmlNode.value,
      children: [{ text: '' }],
      flow: true,
      selection: codeEditorSelection,
    };

    Editor.withoutNormalizing(editor, () => {
      if (isParagraph(blockEntry[0])) {
        Transforms.removeNodes(editor, { at: blockEntry[1] });
        Transforms.insertNodes(editor, htmlElement, { at: blockEntry[1], select: true });
      } else {
        // @note: We grab the whole fragment here, because we're relying on
        // this normalizer to setup the table correctly on load.
        // @readme/markdown@v6 doesn't recognize blocks in tables in this way,
        // but since this is being deprecated soon, I'm ok with this hack.
        const fragment = editor.deserialize(string);
        Transforms.removeNodes(editor, { at: blockEntry[1], match: (n, p) => p.length === blockEntry[1].length + 1 });
        Transforms.insertNodes(editor, fragment, { at: [...blockEntry[1], 0] });
      }
    });
  };

export default [convertToHtml];
