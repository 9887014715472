import type { HTMLAttributes } from 'react';

import React from 'react';

import useSelectedClassName from '@ui/MarkdownEditor/editor/useSelectedClassName';
import type { TableHeaderElement } from '@ui/MarkdownEditor/types';
import useClassName from '@ui/MarkdownEditor/useClassName';

import classes from './style.module.scss';

interface Props {
  attributes: HTMLAttributes<HTMLElement>;
  children: React.ReactNode;
  element: TableHeaderElement;
}

const TableHeader = ({ attributes, children, element }: Props) => {
  return (
    <thead {...attributes}>
      <tr className={useClassName(classes.TableHeader, useSelectedClassName(element))}>{children}</tr>
    </thead>
  );
};

export default TableHeader;
