import Component from './Component';
import normalizeNode from './normalizeNode';
import operations from './operations';
import { deserialize, serialize } from './serialize';
import { type, isHtml, isHtmlFlow } from './shared';
import slashMenu from './slashMenu';

const Html = {
  Component,
  deserialize,
  is: isHtml,
  isHtml,
  isHtmlFlow,
  isVoid: true,
  normalizeNode,
  operations,
  serialize,
  slashMenu,
  type,
};

export default Html;
