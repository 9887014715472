/* eslint-disable consistent-return */
import { Editor, Transforms } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type { Normalizer } from '@ui/MarkdownEditor/types';

const trailingVoids: Normalizer =
  next =>
  (editor, [node]) => {
    if (!Editor.isEditor(node)) return next();
    if (!Editor.isVoid(editor, editor.children[editor.children.length - 1])) return next();

    Transforms.insertNodes(editor, emptyNode(), { at: [editor.children.length] });
  };

export default trailingVoids;
