import type { Node } from 'slate';

import { Element } from 'slate';

import { MenuHandleTypes } from '@ui/MarkdownEditor/enums';
import type { MenuHandle } from '@ui/MarkdownEditor/types';

export const type = 'menu-handle' as MenuHandle['type'];
export const isMenuHandle = (node: Node): node is MenuHandle => Element.isElementType(node, type);

export const prefixes = {
  [MenuHandleTypes.reusableContent]: '<',
  [MenuHandleTypes.variable]: '<',
  [MenuHandleTypes.mdxVariable]: '{',
  [MenuHandleTypes.link]: '\\[',
  [MenuHandleTypes.emoji]: ':',
};
