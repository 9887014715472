import type { RenderElementProps } from 'slate-react';

import React, { useCallback } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import useClassy from '@core/hooks/useClassy';

import SelectionWrapper from '@ui/MarkdownEditor/editor/SelectionWrapper';
import type { JsxFlowElement } from '@ui/MarkdownEditor/types';

import CodeEditor from '../../CodeEditor';

import styles from './style.module.scss';

interface Props extends RenderElementProps {
  element: JsxFlowElement;
}

const JsxFlow = ({ attributes, element, children }: Props) => {
  const editor = useSlateStatic();
  const bem = useClassy(styles, 'JsxFlowBlock');

  const onCodeChange = useCallback(
    value => {
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(editor, { value }, { at: path });
    },
    [editor, element],
  );

  return (
    <SelectionWrapper element={element} {...attributes}>
      {children}
      <CodeEditor
        className={bem('&')}
        code={element.value}
        element={element}
        language={'jsx'}
        onChange={onCodeChange}
      />
    </SelectionWrapper>
  );
};

export default JsxFlow;
